import Link from "next/link";

import Styles from "./Header.module.scss";

import SoundBar from "components/SoundBar/SoundBar";
import Navbar from "components/Navbar/Navbar";
import Image from "next/image";

export default function Header() {
  return (
    <header className={Styles.container}>
      <Link href="/" className="relative z-10 w-10 h-10 md:w-14 md:h-14">
        {/* <span className="relative z-10 text-stone-400 font-bold tracking-widest text-3xl md:text-4xl">
          BAR<span className="text-stone-600">LAVIE</span>
        </span> */}
        <Image src="/images/logo.svg" alt="Bar Lavie logo" fill></Image>
      </Link>

      <div className="flex items-center gap-8 ml-4">
        <SoundBar />
        <Navbar />
      </div>
    </header>
  );
}
