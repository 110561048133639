import Head from "next/head";
import { useContextProvider } from "AppContextProvider";
import { useEffect, useState, useRef } from "react";

export default function Layout({ children }) {
  const [sounds, setSounds] = useState(null);
  const context = useContextProvider();
  const audioRef = useRef(null);

  useEffect(() => {
    setSounds({
      click: new Audio("/sounds/click.wav"),
      swoosh: new Audio("/sounds/swoosh.wav"),
      duck: new Audio("/sounds/duck.wav"),
    });

    document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`);
  }, []);

  useEffect(() => {
    const soundsTriggers = document.querySelectorAll("[data-sound]");
    soundsTriggers.forEach((el) => {
      el.addEventListener(el.dataset.soundEvent ? el.dataset.soundEvent : "click", handleClick);
    });
    /**
     * Replacing a Media Source Sequentially
     * https://developer.apple.com/library/archive/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/ControllingMediaWithJavaScript/ControllingMediaWithJavaScript.html#//apple_ref/doc/uid/TP40009523-CH3-SW5
     */
    function handleClick() {
      if (context.withSound) {
        // if (audioRef.current) {
        //   audioRef.current.pause();
        //   audioRef.current.currentTime = 0;
        // }
        // const sound = this.dataset.sound;
        audioRef.current.src = sounds[this.dataset.sound].src;
        audioRef.current.load();
        audioRef.current.play();
      }
    }
    // cleanup to avoid memory leaks and prevent multiple event listeners
    return () => {
      soundsTriggers.forEach((el) => {
        el.removeEventListener(el.dataset.soundEvent ? el.dataset.soundEvent : "click", handleClick);
      });
    };
  }, [context.withSound, sounds]);

  const metaTitle = "Bar Lavie | Fullstack web developer and front-end consultant";
  const metaDescription =
    "I help small-medium business to create an outstanding web applications using cutting edge technologies.";

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Web development, Web development consulting, front-end development"></meta>
        <meta charSet="utf-8"></meta>
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:url" content="https://barlavie.dev/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/og-image.png" />
        {/* https://favicon.io/favicon-converter/ */}
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <audio ref={audioRef}></audio>
      <>{children}</>
    </>
  );
}
