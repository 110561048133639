import { useEffect, useState } from "react";
import Styles from "./Hamburger.module.scss";

export default function Hamburger(props) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    props.handleClick(isActive);
  }, [props, isActive]);

  return (
    <button
      type="button"
      className={`${Styles.container} ${isActive ? Styles.isActive : ""}`}
      onClick={() => setIsActive(!isActive)}
    >
      <span className={Styles.open}>
        <span></span>
        <span></span>
      </span>
      <span className={Styles.close}>
        <div>
          <span></span>
        </div>
        <div>
          <span></span>
        </div>
      </span>
    </button>
  );
}
