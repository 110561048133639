import Image from "next/image";
import Link from "next/link";

import { useState, useEffect } from "react";
import { useTrail, animated, useSprings, config } from "react-spring";

import Styles from "./Navbar.module.scss";

import Social from "../Social/Social";
import Hamburger from "../Hamburger/Hamburger";

const menu = [
  { name: "Instruction", link: "/instruction", content: "Schedule a personalized programing session" },
  { name: "Blog", link: "/blog", tbd: true, content: "My instagram feed, short stories about the web." },
  { name: "Resources", link: "/tools", tbd: true, content: "The tools I use to build websites and apps." },
];

export default function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
  const [navHidden, setNavHidden] = useState(true);

  useEffect(() => {
    setNavHidden(false);
  }, []);

  const [trails] = useTrail(
    menu.length,
    (i) => ({
      delay: navOpen ? 0 : 200,
      from: { opacity: navOpen ? 0 : 1, translateY: navOpen ? "100%" : "0%" },
      to: { opacity: navOpen ? 1 : 0, translateY: navOpen ? "0%" : "100%" },
      config: { mass: 5, tension: 2000, friction: 200, duration: navOpen ? 200 : 200 },
      onRest: () => {
        api.start((i) => ({
          delay: i * 200,
          opacity: navOpen ? 1 : 0,
        }));
      },
    }),
    [navOpen]
  );

  const [styles, api] = useSprings(menu.length, () => ({
    opacity: 0,
    config: { ...config.gentle, duration: 600 },
  }));

  return (
    <>
      <Hamburger
        handleClick={(openOrClose) => {
          setNavOpen(openOrClose);
        }}
      />

      <nav className={`${Styles.navbar} ${navOpen ? Styles.isActive : ""} ${navHidden ? "hidden" : ""}`}>
        <div className={Styles.inner}>
          <ul>
            {trails.map((props, index) => {
              return (
                <li key={index}>
                  <Link href={menu[index].link} className={menu[index].tbd ? "pointer-events-none" : ""}>
                    <animated.span style={props}>
                      {menu[index].name}
                      {menu[index].tbd && (
                        <span className="bg-stone-900 text-xs px-2 py-1 rounded-lg text-stone-400">coming soon</span>
                      )}
                    </animated.span>
                    <animated.p style={styles[index]} className="text-sm text-stone-400">
                      {menu[index].content}
                    </animated.p>
                  </Link>
                </li>
              );
            })}
          </ul>

          <Social wrapperClassName="mt-20 text-stone-400 md:gap-8" iconClassName={"w-7 h-7 md:w-10 md:h-10"} />

          <div
            onClick={(e) => {
              e.target.style.transform = "rotate(15deg)";
              setTimeout(() => {
                e.target.style.transform = "rotate(0deg)";
              }, 300);
            }}
            data-sound="duck"
            // data-sound-event="mouseover"
            className="absolute bottom-10 right-10 w-36 h-36 md:w-64 md:h-64 md:right-16 md:bottom-16 cursor-pointer"
          >
            <Image src={`/images/duck.svg`} alt="Menu duck image" fill />
          </div>
        </div>
      </nav>
    </>
  );
}
