import "../styles/globals.scss";
import Layout from "components/Layout/Layout";
import { AppContextProvider } from "AppContextProvider";
import { CookiesProvider } from "react-cookie";
import PageLayout from "components/PageLayout/PageLayout";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => <PageLayout>{page}</PageLayout>);

  return (
    // https://www.techomoro.com/how-to-add-google-recaptcha-v3-in-a-next-js-form/
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: false,
        defer: true, // false
        appendTo: "body",
        nonce: undefined,
      }}
    >
      <AppContextProvider>
        <CookiesProvider>
          <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
        </CookiesProvider>
      </AppContextProvider>
    </GoogleReCaptchaProvider>
  );
}
