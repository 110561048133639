// reference: https://www.bundleapps.io/blog/nextjs-context-api-tutorial

import { createContext, useContext, useState, useEffect } from "react";

const AppContext = createContext();

export const useContextProvider = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const [withSound, setWithSound] = useState(true);

  return (
    <AppContext.Provider
      value={{
        withSound,
        setWithSound,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
