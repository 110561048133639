import { memo, useEffect } from "react";
import Styles from "./SoundBar.module.scss";
import { useSprings, animated } from "react-spring";
import { useContextProvider } from "AppContextProvider";
import { useCookies } from "react-cookie";

function SoundBar() {
  const context = useContextProvider();
  const [cookie, setCookie] = useCookies(["withSound"]);

  useEffect(() => {
    if (cookie.withSound === undefined) {
      setCookie("withSound", true, { path: "/" });
    }

    if (cookie.withSound === "false") {
      context.setWithSound(false);
    }
  });

  const bars = [
    {
      from: { transform: "scaleY(0.1)" },
      to: { transform: "scaleY(0.2)" },
      whenPaused: { transform: "scaleY(0)" },
    },
    {
      from: { transform: "scaleY(0.6)" },
      to: { transform: "scaleY(0.3)" },
      whenPaused: { transform: "scaleY(0.6)" },
    },
    {
      from: { transform: "scaleY(0.4)" },
      to: { transform: "scaleY(0.8)" },
      whenPaused: { transform: "scaleY(0)" },
    },
    {
      from: { transform: "scaleY(0.3)" },
      to: { transform: "scaleY(0.5)" },
      whenPaused: { transform: "scaleY(0.6)" },
    },

    {
      from: { transform: "scaleY(0.1)" },
      to: { transform: "scaleY(0.2)" },
      whenPaused: { transform: "scaleY(0)" },
    },
  ];

  const springs = useSprings(
    bars.length,
    bars.map((item) => ({
      from: item.from,
      to: context.withSound ? [item.to, item.from] : [item.to, item.whenPaused],
      config: { duration: context.withSound ? 500 : 200 },
      loop: context.withSound,
    }))
  );

  return (
    <button
      className={`${Styles.container} ${context.withSound ? "" : Styles.paused}`}
      onClick={() => {
        context.setWithSound(!context.withSound);
        setCookie("withSound", !context.withSound, { path: "/" });
      }}
    >
      <div>
        {springs.map((styles, item) => (
          <animated.div style={styles} key={item} />
        ))}
      </div>
    </button>
  );
}
export default memo(SoundBar);
