import Link from "next/link";

import { GitHub, Linkedin } from "react-feather";
export default function Social(props) {
  const social = [
    // { name: "facebook", link: "https://www.facebook.com/barlavie909/", icon: Facebook },
    {
      name: "Linkedin",
      link: "https://www.linkedin.com/in/barlavie/",
      icon: <Linkedin fill="currentColor" stroke="none" className={`${props.iconClassName}`} />,
    },
    {
      name: "GitHub",
      link: "https://github.com/bar-lavie",
      icon: <GitHub fill="currentColor" stroke="none" className={`${props.iconClassName}`} />,
    },
  ];
  return (
    <div className={`flex gap-4 ${props.wrapperClassName}`}>
      {social.map((item, index) => {
        return (
          <Link href={item.link} key={index} target="_blank" rel="noopener noreferrer">
            <span title={item.name} className="cursor-pointer">
              {item.icon}
            </span>
          </Link>
        );
      })}
    </div>
  );
}
